import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/work/BC_Supermarket/bc-supermarket-docs-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "instant-load"
    }}>{`Instant Load`}</h1>
    <p>{`Instant Load can speed up your store dramatically by predict and preload the pages user may view forward. It makes user navigation effectively instant in most cases. Let's preview this feature in this video:`}</p>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/5IbpKMMJGFU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <p>{`To enable this feature in `}<strong parentName="p">{`Theme Editor`}</strong>{` > `}<strong parentName="p">{`Global`}</strong>{` > tick on `}<strong parentName="p">{`Enable Instant-Load for faster browsing pages (experiment)`}</strong>{`.`}</p>
    <p>{`Note that this feature only works for certain pages such as: `}<em parentName="p">{`Category pages`}</em>{`, `}<em parentName="p">{`product pages`}</em>{`, `}<em parentName="p">{`brand pages`}</em>{`, `}<em parentName="p">{`blog`}</em>{` and `}<em parentName="p">{`static web pages`}</em>{`. Other pages will be loaded as normal.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      